import React from 'react';
import {
    IndexRoute,
    Route,
} from 'react-router';
import App from './app/app.jsx';
import { PeoplePathnameSegmentType } from './app/navigation/models';
import { SERVICE_COMPANION_ROUTE_PATHS } from './contentManagement/tabs/constants';
import { ROUTE_PATH_SCHEDULE } from './ministry/servingOpportunities/global/constants.js';
import {
    getReactPluginInstance,
    isAnalyticsAvailable,
    trackPageView,
    withAnalytics,
} from './tools/analytics/index.js';

const AnalyticsReactPluginInstance = getReactPluginInstance();

// HOC: With Page Tracking
const withPageTracking = (component) => (props) => {
    const {
        // eslint-disable-next-line react/prop-types
        location: {
            // eslint-disable-next-line react/prop-types
            pathname,
        },
    } = props;

    /**
     * we don't currently have any requirements on what data to track, so just
     * passing null for state
     */
    trackPageView(pathname, null);

    const WrappedComponent = withAnalytics(AnalyticsReactPluginInstance, component);

    // eslint-disable-next-line react/jsx-filename-extension, react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
};

// HOC: Get Component
const getComponent = ({ callback, promise }) => {
    promise.then((module) => {
        if (isAnalyticsAvailable()) {
            return callback(null, withPageTracking(module.default));
        }

        return callback(null, module.default);
    });
};

const servingOppsCheckInRoutes = [
    <IndexRoute
        getComponent={(location, callback) => getComponent({
            callback,
            promise: import('./ministry/myServingOpps/checkIn'),
        })}
    />,

    <Route
        getComponent={(location, callback) => getComponent({
            callback,
            promise: import('./ministry/myServingOpps/checkIn/allServingOppsTab'),
        })}
        path="serving-opps"
    />,

    <Route
        getComponent={(location, callback) => getComponent({
            callback,
            promise: import('./ministry/myServingOpps/checkIn/allVolunteersTab'),
        })}
        path="volunteers"
    />,
];

const servingOpportunityRoutes = [
    <IndexRoute
        getComponent={(location, callback) => getComponent({
            callback,
            promise: import('./ministry/servingOpportunities/servingOpportunity/schedule'),
        })}
        key="serving_opp--index_route"
    />,
    <Route
        getComponent={(location, callback) => getComponent({
            callback,
            promise: import('./ministry/servingOpportunities/servingOpportunity/schedule'),
        })}
        key="serving_opp--schedule_route"
        path={ROUTE_PATH_SCHEDULE}
    />,
    <Route
        getComponent={(location, callback) => getComponent({
            callback,
            promise: import('./ministry/servingOpportunities/servingOpportunity/roster'),
        })}
        key="serving_opp--roster_route"
        path="roster(/:mode)"
    />,
    <Route
        getComponent={(location, callback) => getComponent({
            callback,
            promise: import('./ministry/servingOpportunities/servingOpportunity/settings'),
        })}
        key="serving_opp--settings_route"
        path="settings"
    />,
    <Route
        getComponent={(location, callback) => getComponent({
            callback,
            promise: import('./ministry/servingOpportunities/servingOpportunity/communications'),
        })}
        key="serving_opp--communications_route"
        path="communications"
    />,
];

/**
 * Main Route Tree for HC Admin
 * IMPORTANT: To help readability and maintainability, please try to keep this file organized.
 * In general, try to keep things in an order that roughly corresponds with HC Admin application
 * navigation.
 */
export default (
    <Route
        component={App}
        path="/"
    >
        {/* Welcome (Home Page) */}
        <IndexRoute
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./welcome/welcome.jsx'),
            })}
        />

        {/* About (unauthenticated page that shows the component versions) */}
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./app/about'),
            })}
            path="/about"
        />

        {/* Environments (unauthenticated page that can show API versions for all HC environmemts) */}
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./app/environments'),
            })}
            path="/environments"
        />

        {/* Activity */}
        {/* TODO/FIXME: I believe this page is defunct.  We might consider removing it. */}
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./js/components/ActivityDrawer/Activity.react.js'),
            })}
            path="/activity"
        />

        {/* My Account */}
        <Route path="/my-account">
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./myAccount/preferences'),
                })}
                path="preferences"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./myAccount/changePassword/changePassword.jsx'),
                })}
                path="change-password"
            />
        </Route>

        {/* START: My Stuff (formerly known as My Dashboard) */}
        <Route path="/my-dashboard">
            {/* START: My Follow Ups */}
            <Route path="follow-ups">
                <Route path=":mode">
                    <IndexRoute
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise:
                                location.params.mode === 'all' ?
                                    import('./followUps/tasks/overview/overview.jsx') :
                                    import('./followUps/tasks/taskGroups/taskGroups.jsx'),
                        })}
                    />
                    <Route
                        getComponent={(location, callback) => {
                            switch (location.params.mode) {
                                case 'all':
                                    getComponent({
                                        callback,
                                        promise: import('./followUps/tasks/overviewTaskListPage/overviewTaskListPage.jsx'),
                                    });
                                    break;
                                default:
                                    getComponent({
                                        callback,
                                        promise: import('./followUps/tasks/taskList/taskListPage.jsx'),
                                    });
                            }
                        }}
                        path=":workflowStatus/:taskTemplateId(/task/:taskId)"
                    />
                </Route>
            </Route>

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./followUps/tasks/overviewTaskListPage/overviewTaskListPage.jsx'),
                })}
                path="follow-ups/:mode/:taskTemplateId/assignee/:assigneeId(/task/:taskId)"
            />

            <Route path="management">
                <Route path=":mode">
                    <IndexRoute
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise:
                                location.params.mode === 'escalated' ?
                                    import('./followUps/tasks/taskGroups/taskGroups.jsx') :
                                    import('./followUps/tasks/overview/overview.jsx'),
                        })}
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise:
                                location.params.mode === 'escalated' ?
                                    import('./followUps/tasks/taskList/taskListPage.jsx') :
                                    import('./followUps/tasks/overviewTaskListPage/overviewTaskListPage.jsx'),
                        })}
                        path=":workflowStatus/:taskTemplateId(/task/:taskId)"
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./followUps/tasks/overviewTaskListPage/overviewTaskListPage.jsx'),
                        })}
                        path="(:taskTemplateId/assignee/:assigneeId(/task/:taskId))"
                    />
                </Route>
            </Route>
            {/* END: My Follow Ups */}

            {/* Notification */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./notificationCenter/notificationCenter.js'),
                })}
                path="notifications"
            />

            {/* START: My Events */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/eventsMyEvents.jsx'),
                })}
                path="my-events"
            />
            <Route path="my-events/:id">
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/eventDetails/index.jsx'),
                    })}
                    path="overview"
                />

                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/eventDetails/eventDetailsEdit.jsx'),
                    })}
                    path="edit"
                />

                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/occurrenceSchedule/index.jsx'),
                    })}
                    path="occurrence-schedule(/:occurrenceId)"
                />

                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/registrationRoster.js'),
                    })}
                    path="registration-roster"
                />

                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/servingOpportunities.jsx'),
                    })}
                    path="serving-opps"
                />

                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/communications/communications'),
                    })}
                    path="communications"
                />

                <Route path="check-in">
                    <IndexRoute
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./event/eventsCentral/checkIn'),
                        })}
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./event/eventsCentral/checkIn/eventVolunteers'),
                        })}
                        path="volunteers"
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./event/eventsCentral/checkIn/eventAttendees/index.jsx'),
                        })}
                        path="attendees"
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./event/eventsCentral/checkIn/eventCommunications/index.jsx'),
                        })}
                        path="communications"
                    />
                </Route>
            </Route>

            <Route path="my-events/:eventId/serving-opps/:id">
                <Route path="check-in">
                    {servingOppsCheckInRoutes}
                </Route>
                {servingOpportunityRoutes}
            </Route>
            {/* END: My Events */}
        </Route>
        {/* END: My Stuff (formerly known as My Dashboard) */}

        {/* START: Features within 'My Stuff' that don't have nested routes */}
        {/* START: My Serving Opps */}
        <Route path="/my-serving-opps">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/myServingOpps'),
                })}
                title="My Serving Opps"
            />

            <Route path="(:id/)check-in">
                {servingOppsCheckInRoutes}
            </Route>

            <Route path=":id">
                {servingOpportunityRoutes}
            </Route>
        </Route>
        {/* END: My Serving Opps */}
        {/* START: My Ministry */}
        <Route path="/my-ministry">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry'),
                })}
                title="My Ministry"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/communications'),
                })}
                path="communications/:ministryId/:churchEntityId"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministryOverview'),
                })}
                path="overview/:id/:location"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministryRoster/memberVolunteers.jsx'),
                })}
                path="volunteers/members/:id/:location(/:ministryMemberId)"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministryRoster/interestedVolunteers.jsx'),
                })}
                path="volunteers/interested/:id/:location(/:ministryMemberId)"
            />
            <Route path="serving-opportunities">
                <Route path="check-in">
                    {servingOppsCheckInRoutes}
                </Route>
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./ministry/servingOpportunities'),
                    })}
                    path=":id/:location"
                />
            </Route>
            <Route path="serving-opportunity/:id">
                <Route path="check-in">
                    {servingOppsCheckInRoutes}
                </Route>
                <Route path=":location">
                    {servingOpportunityRoutes}
                </Route>
            </Route>
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/settings'),
                })}
                path="settings/:pageSection/:ministryId/:churchEntityId"
            />
        </Route>
        {/* END: My Ministry */}
        {/* END: Features within 'My Stuff' that don't have nested routes */}

        {/* START: People */}
        <Route path={`/${PeoplePathnameSegmentType.People}`}>
            <Route path={PeoplePathnameSegmentType.SearchOrAdd}>
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./people/search/peopleSearch.jsx'),
                    })}
                />
                <Route path={PeoplePathnameSegmentType.Record}>
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./people/record/record'),
                        })}
                        path={`:personId(${PeoplePathnameSegmentType.Note}/:noteId)`}
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./js/components/Person/Manager/PersonRecord.react.js'),
                        })}
                        path={`:personId/${PeoplePathnameSegmentType.Edit}`}
                    />
                </Route>
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Person/Manager/PersonPhoto.react.js'),
                    })}
                    path="photo/:personId"
                />
            </Route>

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./people/record/printPersonRecordPage'),
                })}
                path="print/:personId"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./auditLog/globalPersonAuditLog.js'),
                })}
                path="audit-log"
            />

            <Route path="duplicate-records">
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Person/Duplicate/PersonDuplicateReport.react.js'),
                    })}
                    path="duplicate(/:reportId)(/:mode)"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Person/Merge/PersonMerge.react.js'),
                    })}
                    path="merge/:person1Id/:person2Id(/processed/:refId)"
                />
            </Route>

            <Route path="settings">
                {/* Milestones */}
                <Route path="milestones">
                    <IndexRoute
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./people/settings/milestones/milestones.jsx'),
                        })}
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./people/settings/milestones/details/details.jsx'),
                        })}
                        path=":milestoneId/details"
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./people/settings/milestones/details/followUps.jsx'),
                        })}
                        path=":milestoneId/follow-ups"
                    />
                </Route>

                {/* Note Categories */}
                <Route path="note-categories">
                    <IndexRoute
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./js/components/Security/SecurityNoteCategorySearch.react.js'),
                        })}
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./js/components/Security/SecurityNoteCategoryAdd.react.js'),
                        })}
                        path="add"
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./js/components/Security/SecurityNoteCategoryEdit.react.js'),
                        })}
                        path="edit/:noteCategoryId"
                    />
                    <Route
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./js/components/Security/SecurityNoteCategoryView.react.js'),
                        })}
                        path="view/:noteCategoryId"
                    />
                </Route>

                { /* Children & Students */ }
                <Route path="grade-advancement">
                    <IndexRoute
                        getComponent={(location, callback) => getComponent({
                            callback,
                            promise: import('./people/settings/gradeAdvancement'),
                        })}
                    />
                </Route>
            </Route>
        </Route>
        {/* END: People */}

        {/* START: Ministries Central */}
        <Route path="/ministries-central">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral'),
                })}
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral'),
                })}
                path="active"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/ministryCentralInactiveList.jsx'),
                })}
                path="inactive"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/quickView.jsx'),
                })}
                path="quickview"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/topLevel/ministryCentralList.jsx'),
                })}
                path="view(/:id)(/:location)"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/ministryAdd.jsx'),
                })}
                path="add"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/ministrySearch.jsx'),
                })}
                path="search"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/topLevel/ministryCentralList.jsx'),
                })}
                path="list"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/topLevel/ministryCentralSettings.jsx'),
                })}
                path="categories"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/topLevel/ministryCentralSettings.jsx'),
                })}
                path="tags"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/recommendedMinistries.jsx'),
                })}
                path="recommended-ministries"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/central/ministryCentralLocations.jsx'),
                })}
                path="locations(/:id)"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./ministry/ministriesCentral/central/ministryCentralWebSettings.jsx'),
                })}
                path="web-settings(/:id)"
            />

            <Route path="serving-opps-catalogs">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./ministry/ministriesCentral/servingOppsCatalogs'),
                    })}
                />
            </Route>
        </Route>
        {/* END: Ministries Central */}

        {/* START: Events Central */}
        <Route path="/events-central">

            <Route path="worship-service-setup">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Admin/WorshipServiceSetup/AdminWorshipServiceOverview.react.js'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Admin/WorshipServiceSetup/AdminWorshipServiceSettings.jsx'),
                    })}
                    path="settings"
                />
            </Route>

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/eventsOverview.jsx'),
                })}
                path="overview(/:level)"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/eventsCanceled.jsx'),
                })}
                path="canceled"
            />

            <Route path="event/:eventId/serving-opps/:id">
                <Route path="check-in">
                    {servingOppsCheckInRoutes}
                </Route>
                {servingOpportunityRoutes}
            </Route>

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/eventDetails/index.jsx'),
                })}
                path="event/:id/overview"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/eventDetails/eventDetailsEdit.jsx'),
                })}
                path="event/:id/edit"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/occurrenceSchedule/index.jsx'),
                })}
                path="event/:id/occurrence-schedule(/:occurrenceId)"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/registrationRoster.js'),
                })}
                path="event/:id/registration-roster"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/servingOpportunities.jsx'),
                })}
                path="event/:id/serving-opps"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/communications'),
                })}
                path="event/:id/communications"
            />

            <Route path="event/:id/check-in">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/checkIn'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/checkIn/eventVolunteers'),
                    })}
                    path="volunteers"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/checkIn/eventAttendees/index.jsx'),
                    })}
                    path="attendees"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/checkIn/eventCommunications/index.jsx'),
                    })}
                    path="communications"
                />
            </Route>

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/eventAccommodationSettings.js'),
                })}
                path="accommodation-settings"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/categorySettings'),
                })}
                path="category-settings"
            />

            <Route path="event-catalogs">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./event/eventsCentral/eventCatalogs'),
                    })}
                />
            </Route>

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/eventRoomSettings.js'),
                })}
                path="room-settings"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./event/eventsCentral/eventTagSettings.jsx'),
                })}
                path="tag-settings"
            />
        </Route>
        {/* END: Events Central */}

        {/* START: Communications Central */}
        <Route path="/communications-central">
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/communicationsCentral'),
                })}
                path="email-templates/:action"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/communicationsCentralTemplateLibrary'),
                })}
                path=":id/template-library"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/emailTemplates/emailTemplateBuilder/emailTemplateBuilder'),
                })}
                path="email-templates/:id/email-template-builder"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/emailTemplates/emailTemplateOverview/emailTemplateOverview'),
                })}
                path="email-templates/:id/overview"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/segmentSearch'),
                })}
                path="segment-search"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/segmentSearch/segmentRecord/segmentRecord'),
                })}
                path="segment-search/segment/:id/overview"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/pushNotification/listPage/listPage'),
                })}
                path="push-notification"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/pushNotification/overview/pushNotificationRecord'),
                })}
                path="push-notification/:id/overview"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/communication/listPage/listPage'),
                })}
                path="communication"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./communicationsCentral/communication/communicationDetails/communicationDetails'),
                })}
                path="communication/:id/overview"
            />
        </Route>
        {/* END: Communications Central */}

        {/* START: Content Management ('Mobile Engagement') */}
        <Route path="/content-management">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./contentManagement/serviceCompanion.jsx'),
                })}
            />
            <Route path="service-companion">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./contentManagement/serviceCompanion.jsx'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./contentManagement/tabs/serviceCompanionOverview'),
                    })}
                    path={`:id/${SERVICE_COMPANION_ROUTE_PATHS.overview}`}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./contentManagement/tabs/serviceCompanionOverview'),
                    })}
                    path={`:id/${SERVICE_COMPANION_ROUTE_PATHS.overview}/edit`}
                    props={{ isEdit: true }}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./contentManagement/tabs/serviceCompanionLocations'),
                    })}
                    path={`:id/${SERVICE_COMPANION_ROUTE_PATHS.locations}`}
                />
            </Route>
            <Route path="digital-program">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./digitalProgram/digitalPrograms'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./digitalProgram/builder/digitalProgramBuilderPage'),
                    })}
                    path=":id/builder"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./digitalProgram/digitalProgramDetails'),
                    })}
                    path=":id/details"
                />
            </Route>
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./contentManagement/serviceSeriesBuilder.jsx'),
                })}
                path="service-series-builder"
            />
        </Route>
        {/* END: Content Management ('Mobile Engagement') */}

        {/* START: Journey */}
        <Route path="/journeys">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./journey/journeys'),
                })}
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./journey/journeyOverview'),
                })}
                path="overview/:id"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./journey/journeyBuilder'),
                })}
                path="builder/:id"
            />
            <Route path="suggested">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./journey/journeySuggested'),
                    })}
                />
            </Route>

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./journey/journeyCategories'),
                })}
                path="category-settings"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./journey/journeyTags'),
                })}
                path="tag-settings"
            />
        </Route>
        {/* END: Journey */}

        {/* START: Giving Central */}
        <Route path="giving/activity">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./giving/givingActivity/listPage'),
                })}
            />
        </Route>

        <Route path="giving/campaigns">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./giving/campaigns/listPage'),
                })}
            />
        </Route>

        <Route path="giving/designations">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./giving/designations/listPage'),
                })}
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./giving/designations/overviewPage'),
                })}
                path=":designationId/overview"
            />
        </Route>

        <Route path="giving/scheduled-recurring-gift">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./giving/givingActivity/scheduledRecurringGift'),
                })}
            />
        </Route>

        <Route path="giving/commitments">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./giving/givingActivity/commitments'),
                })}
            />
        </Route>

        <Route path="giving/shared-accounts">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./giving/sharedAccounts'),
                })}
            />
        </Route>
        {/* END: Giving Central */}

        {/* START: Data Capture */}
        <Route path="/data-capture">
            {/* Connection Forms 2.0 */}
            <Route path="connection-forms">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionForms/templates/listPage/listPage.jsx'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionForms/templates/overviewPage/overviewPage.jsx'),
                    })}
                    path=":id/overview"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionForms/templates/formBuilderPage'),
                    })}
                    path=":id/builder"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionForms/templates/autoReplyEmailPage/autoReplyEmailPage.jsx'),
                    })}
                    path=":id/auto-reply-email"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionForms/templates/followUpsPage/followUpsPage.jsx'),
                    })}
                    path=":id/follow-ups"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionForms/entries/listPage/listPage.jsx'),
                    })}
                    path=":id/entries"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionForms/entries/entryEditor/entryEditor.jsx'),
                    })}
                    path=":id/entries/create"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionForms/entries/entryEditor/entryEditor.jsx'),
                    })}
                    path=":id/entries/edit/:entryId"
                />
            </Route>

            {/* Connection Cards (Response Cards) 1.0 */}
            <Route path="connection-cards">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/ResponseCard/ResponseCardSearch.react.js'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/ResponseCard/ResponseCardManager.react.js'),
                    })}
                    path="add"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/ResponseCard/ResponseCardManager.react.js'),
                    })}
                    path="edit/:responseCardId"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionCards/entriesByCard.jsx'),
                    })}
                    path="entries/browse/:responseCardId"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionCards/entryManager.js'),
                    })}
                    path="entries/add/:responseCardId"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionCards/entryManager.js'),
                    })}
                    path="entries/edit/:responseCardEntryId"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionCards/entryView.js'),
                    })}
                    path="entries/view/:responseCardEntryId"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionCards/entriesByPerson.js'),
                    })}
                    path="entries/person/:personId"
                />
            </Route>

            {/* Connection Questions */}
            <Route path="connection-questions">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionQuestions/list/list.jsx'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionQuestions/details/details.jsx'),
                    })}
                    path=":connectionQuestionId/details"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./connectionQuestions/details/followUps.jsx'),
                    })}
                    path=":connectionQuestionId/follow-ups"
                />
            </Route>

            {/* Weekend Service ('Weekend Metrics Capture') */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./metricsDataCapture/dataEntry.jsx'),
                })}
                path="weekend-service"
            />

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./connectionForms/templates/settings/formCategorySettings'),
                })}
                path="category-settings"
            />
        </Route>
        {/* END: Data Capture */}

        {/* START: Insights */}
        <Route path="/insights">
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./insights/dashboard/dashboard.js'),
                })}
                path="dashboard"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./insights/weekendAttendanceFlashReport.js'),
                })}
                path="weekend-attendance-flash-report"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./insights/weekendSalvationsFlashReport.js'),
                })}
                path="weekend-salvations-flash-report"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./insights/weekendBaptismsFlashReport.js'),
                })}
                path="weekend-baptisms-flash-report"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./insights/weekendParkingFlashReport.js'),
                })}
                path="weekend-parking-flash-report"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./insights/weekendOnlineFlashReport.js'),
                })}
                path="weekend-online-flash-report"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./insights/followUpsDashboard/followUpsDashboard.jsx'),
                })}
                path="follow-ups-dashboard"
            />
            <Route
                getComponent={(_, callback) => getComponent({
                    callback,
                    promise: import('./insights/embeddedReports/embeddedCategories'),
                })}
                path="embed-categories"
            />
            <Route
                getComponent={(_, callback) => getComponent({
                    callback,
                    promise: import('./insights/embeddedReports/embeddedReports'),
                })}
                path="embed-reports"
            />
        </Route>
        {/* END: Insights */}

        {/* START: System */}
        <Route path="/system">
            {/* Platform Settings */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./system/platformSettings/platformSettings.jsx'),
                })}
                path="platform-settings/general"
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./system/platformSettings/platformSettings.jsx'),
                })}
                path="platform-settings/data-covenant"
            />

            {/* Campus Settings */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./js/components/ChurchStructure/ChurchStructure.react.js'),
                })}
                isTeam={false}
                path="campus-settings"
            />

            {/* START: Finance GL Configuration */}
            <Route path="/finance">
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./finance/entityList'),
                    })}
                    path=":entity"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./finance/entityDetailsLoader'),
                    })}
                    path=":entity/:id"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./finance/entityDetailsLoader'),
                    })}
                    path=":entity/:id/subaccounts/:subaccountid"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./finance/entityList'),
                    })}
                    path="settings/segments/:entity"
                />

                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./finance/entityDetailsLoader'),
                    })}
                    path="settings/segments/:entity/:id"
                />
            </Route>

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./finance/journalEntries'),
                })}
                path="/journal-entries"
            />
            {/* END: Finance GL Configuration */}

            {/* Users */}
            <Route path="users">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/User/UserSearch.jsx'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/User/UserView.jsx'),
                    })}
                    path="view/:userId"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/User/UserEdit.jsx'),
                    })}
                    path="edit/:userId"
                />
            </Route>

            {/* Teams */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./js/components/ChurchStructure/ChurchStructure.react.js'),
                })}
                isTeam
                path="teams"
            />

            {/* Security & Permissions */}
            <Route path="security-roles">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Security/SecurityRoleSearch.react.js'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Security/SecurityRoleAdd.react.js'),
                    })}
                    path="add"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Security/SecurityRoleEdit.react.js'),
                    })}
                    path="edit/:securityRoleId"
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Security/SecurityRoleView.react.js'),
                    })}
                    path="view/:securityRoleId"
                />
            </Route>

            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./js/components/Security/PermissionList.jsx'),
                })}
                path="permissions"
            />

            {/* People Import */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./js/components/Admin/Import/AdminImport.react.js'),
                })}
                path="people-import"
            />

            {/* People Export */}
            <Route path="people-export">
                <IndexRoute
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./js/components/Admin/Export/AdminExport.react.js'),
                    })}
                />
                <Route
                    getComponent={(location, callback) => getComponent({
                        callback,
                        promise: import('./system/migrationSettings/export/people/people.jsx'),
                    })}
                    path="new"
                />
            </Route>

            {/* Connection Cards 1.0 Export */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./system/migrationSettings/export/connectionCards/connectionCards.jsx'),
                })}
                path="connection-card-export"
            />

            {/* Connection Forms 2.0 Export */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./system/migrationSettings/export/connectionForms/connectionForms.jsx'),
                })}
                path="connection-form-export"
            />
        </Route>
        {/* END: System */}

        {/* START: Dev Tools */}
        <Route path="/dev-tools">
            {/* Elastic Search Index management */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./js/components/Admin/ElasticSearchIndexing/ElasticSearchIndexing.tsx'),
                })}
                path="elastic-search-indexing"
            />
            {/* Schedule Cache Control */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./js/components/Admin/Cache/AdminCacheControl.react.js'),
                })}
                path="cache-control"
            />
            {/* Occurrence Tool */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./js/components/Admin/Schedule/OccurrenceTool.js'),
                })}
                path="occurrence-tool"
            />
            {/* QR Code Tool */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./js/components/Admin/QrCode/QrTool.jsx'),
                })}
                path="qr-tool"
            />
            {/* Temporary Testing Page for Unlayer Email Editor until it is fully integrated in live features */}
            {/* TODO: We can probably retire this now that we are using Unlayer in features.  Unless we can think of a reason to keep it around. */}
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./global/unlayerEmailEditor/testPageForUnlayerEditor.jsx'),
                })}
                path="test-unlayer-email-editor"
            />
        </Route>
        {/* END: Dev Tools */}

        {/* START: Help */}
        <Route path="help">
            <IndexRoute
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./help/help.jsx'),
                })}
            />
            <Route
                getComponent={(location, callback) => getComponent({
                    callback,
                    promise: import('./help/helpArticle.jsx'),
                })}
                path="article/:contentId(/:versionId)"
            />
        </Route>
        {/* END: Help */}

        {/* START: Public-facing pages that don't require authentication */}
        {/* SMS Terms & Conditions and Consent Pages */}
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./smsConsent/smsConsent.jsx'),
            })}
            path="sms-consent(/:token)"
        />
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./smsConsent/smsTermsAndConditions.jsx'),
            })}
            path="sms-terms-and-conditions"
        />

        {/* Published Connection Forms */}
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./connectionForms/public/connectionFormPage'),
            })}
            path="public/connection-form/:connectionFormTemplateId/:connectionFormTemplateSlug"
        />

        {/* START: Public Giving-related Pages */}
        {/*     START: Giving Checkout (formerly known as 'Simple Giving') */}
        {/*     Main Giving Checkout Page */}
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./giving/public/givingCheckout/givingCheckoutPage'),
            })}
            path="/public/giving"
        />

        {/*     Complete a Bank Payment Page */}
        {
          /*    NOTE: Might get refactored, depending on how (and where in user flows)
                we want to handle ACH Bank Account setup that requries microdeposit verification.

                We probably mostly don't want to be doing this at "point of sale" as it is an
                out-of-band process that in live usage will take several business days, so any
                in progress Giving/Payment action is OVER if the desired brand new ACH payment method
                requires microdeposit verification.

                In live mode, real banks may or may not actually require this; some might be able
                to be setup and valdiated automatically; however, we cannot really test this effectively
                in test mode.

                So this might be a separate thing that's not really part of Giving Checkout flow, and
                it might really be more a part of Payment Method management.
           */
        }
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./giving/public/givingCheckout/completeBankPaymentPage'),
            })}
            path="/public/giving/complete-bank-payment"
        />

        {/*     Session Complete Page */}
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./giving/public/givingCheckout/sessionComplete'),
            })}
            path="/public/giving/session-complete"
        />
        {/*     END: Giving Checkout (formerly known as 'Simple Giving') */}

        {/* Giving History Page */}
        {
            /*
                NOTE: May or may not be needed.
                Depends how we want to deal with Giving History in mobile app.
                For web, we likely we will use a widget that is embedded on saddleback.com.
            */
        }
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./giving/public/givingHistory'),
            })}
            path="/public/giving-history"
        />

        {/* Public Designation Details */}
        {
            /** NOTE: Confirm path is correct for designations. Looks like /public/giving/
             * is everything related to check-out, so currently assuming
             * we don't want to have this under /giving/designation-details
             */
        }
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./giving/public/designationDetails'),
            })}
            path="/public/designation-details/:designationId"
        />

        {/* Public Campaign Details */}
        {
            /** NOTE: Confirm path is correct for campaigns. Looks like /public/giving/
             * is everything related to check-out, so currently assuming
             * we don't want to have this under /giving/campaign-details
             */
        }
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./giving/public/campaignDetails'),
            })}
            path="/public/campaign-details/:campaignId"
        />

        {/* Manage Saved Payments Methods Page */}
        {
            /*
                NOTE: May or may not be needed.
                Depends how we want to deal with managing saved payment methods in mobile app.
                For web, we likely we will use a widget that is embedded on saddleback.com.
            */
        }
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./giving/public/paymentMethods'),
            })}
            path="/public/saved-payment-methods"
        />
        {/* END: Public Giving-related Pages */}

        {/* Public Digital Program */}
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./digitalProgram/builder/public/digitalProgramPreviewPage'),
            })}
            path="/public/digital-program/campus/:id"
        />

        {/* END: Public-facing pages that don't require authentication */}

        {/* START: Error Pages */}
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./js/components/Error/403.react.js'),
            })}
            path="/403"
        />
        <Route
            getComponent={(location, callback) => getComponent({
                callback,
                promise: import('./js/components/Error/404.react.js'),
            })}
            path="*"
        />
        {/* END: Error Pages */}
    </Route>
);
