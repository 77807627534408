import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import { Header } from '@saddlebackchurch/react-cm-ui';
import _, {
    chunk,
    isEqual,
    isNil,
    noop,
} from 'lodash';
import { connect } from 'react-redux';
import {
    InjectedRouter,
    withRouter,
} from 'react-router';
import Slider from 'react-slick';
import RelationshipConnectionCard from './global/relationshipConnectionCard';
import Relationship from './models/relationship.model';

type Ref = HTMLDivElement;

type PropTypes = {
    blockSize?: number; // Passed in by Redux
    desktopBackgroundHeight: number;
    isDrawerChild?: boolean;
    isNotesDrawerOpen?: boolean;
    isNotesDrawerExpanded?: boolean;
    desktopHeight: number;
    forwardedRef?: React.RefObject<HTMLDivElement>;
    onCardClick?: () => void;
    relationshipData?: Relationship[];
    router?: InjectedRouter;
    style?: React.CSSProperties;
    handleRef?: (node: any) => void;
};

type StateTypes = {
    isAllInitialSlidesMounted: boolean;
    data: Relationship[];
    isUnslick: boolean;
    shouldRenderSlider: boolean;
};

const mapStateToProps = (state) => {
    const {
        people: {
            record: {
                childrenCheckIn,
                index: {
                    isNotesDrawerExpanded,
                    isNotesDrawerOpen,
                },
                relationships: {
                    data: relationshipData,
                },
            },
        },
    } = state;

    return {
        childrenCheckIn,
        isNotesDrawerExpanded,
        isNotesDrawerOpen,
        relationshipData,
    };
};

const DEFAULT_DATA_CHUNK_SIZE = 25;

class RelationshipConnectionsCarousel extends React.PureComponent<PropTypes, StateTypes> {
    dataChunkKey: number;

    dataChunks: Relationship[][];

    containerRef: React.RefObject<HTMLDivElement>;

    clickedPerson: Relationship;

    lastRenderedSlideOffset: number;

    isCardOnClickPrevented: boolean;

    sliderRef: React.RefObject<HTMLDivElement>;

    backgroundRef: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);

        this.dataChunkKey = 0;

        this.dataChunks = chunk<Relationship>(props.relationshipData, DEFAULT_DATA_CHUNK_SIZE);

        this.state = {
            data: [...this.dataChunks[this.dataChunkKey]],
            isAllInitialSlidesMounted: false,
            isUnslick: false,
            shouldRenderSlider: false,
        };

        this.containerRef = React.createRef();
        this.backgroundRef = React.createRef();
        this.clickedPerson = null;

        this.lastRenderedSlideOffset = props.blockSize * 2;
        this.lastSlideDidMount = this.lastSlideDidMount.bind(this);
        this.onCardClick = this.onCardClick.bind(this);
        this.onSliderAfterChange = this.onSliderAfterChange.bind(this);
        this.onSliderBeforeChange = this.onSliderBeforeChange.bind(this);
    }

    componentDidMount() {
        const {
            forwardedRef,
        } = this.props;

        if (forwardedRef.current) {
            this.setState({
                shouldRenderSlider: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            blockSize: prevBlockSize,
            isNotesDrawerExpanded: prevIsNotesDrawerExpanded,
            isNotesDrawerOpen: prevIsNotesDrawerOpen,
            relationshipData: prevRelationshipData,
        } = prevProps;

        const {
            blockSize,
            isNotesDrawerExpanded,
            isNotesDrawerOpen,
            relationshipData,
        } = this.props;

        if (!isEqual(prevRelationshipData, relationshipData)) {
            this.dataChunkKey = 0;
            this.dataChunks = chunk<Relationship>(
                relationshipData,
                DEFAULT_DATA_CHUNK_SIZE,
            );
            this.setState({
                data: this.dataChunks[this.dataChunkKey],
            });
        }

        const { isAllInitialSlidesMounted } = this.state;

        if ((prevBlockSize !== blockSize && isAllInitialSlidesMounted) ||
            prevIsNotesDrawerOpen !== isNotesDrawerOpen ||
            prevIsNotesDrawerExpanded !== isNotesDrawerExpanded
        ) {
            this.updateSlider();
        }
    }

    onCardClick(person: Relationship) {
        if (!this.isCardOnClickPrevented && person) {
            const {
                isDrawerChild,
                onCardClick = noop,
                router,
            } = this.props;

            this.clickedPerson = person;
            const personId = this.clickedPerson?.personId ?? null;

            if (!isNil(personId)) {
                if (!isDrawerChild) {
                    router.push(`/people/search-or-add/record/${personId}`);
                } else {
                    onCardClick(personId);
                }
            }

            this.clickedPerson = null;
        }
    }

    onSliderAfterChange() {
        this.isCardOnClickPrevented = false;

        this.updateSlider();
    }

    onSliderBeforeChange() {
        this.isCardOnClickPrevented = true;
    }

    lastSlideDidMount() {
        const { isAllInitialSlidesMounted } = this.state;

        if (!isAllInitialSlidesMounted) {
            this.updateSlider();
        }
    }

    updateSlider() {
        const {
            forwardedRef,
        } = this.props;

        if (forwardedRef.current) {
            const {
                blockSize,
                relationshipData,
                isDrawerChild,
                isNotesDrawerExpanded,
                isNotesDrawerOpen,
            } = this.props;
            const { data, isAllInitialSlidesMounted, isUnslick } = this.state;
            const slideEls = forwardedRef.current.querySelectorAll<HTMLDivElement>('.slick-slide');
            let slidesWidth = 0;
            let lastSlideLeftPos = 0;
            let notesWidth = 0;

            if (isNotesDrawerOpen) {
                notesWidth = isNotesDrawerExpanded ? 768 : 320;
            }

            _.map(slideEls, (slide, index) => {
                slidesWidth += slide.offsetWidth + 10; // 10 accounts for the left negative space.

                slide.querySelector('.person_relationship_card').classList
                    .toggle(
                        'person_relationship_card-transparent',
                        !isDrawerChild &&
                        blockSize >= 768 && (
                            slide.getBoundingClientRect().left - 250 < 0 ||
                            slide.getBoundingClientRect().right - 250 > blockSize - notesWidth
                        ),
                    );

                if (slideEls.length === index + 1) {
                    lastSlideLeftPos = slide.getBoundingClientRect().left;
                }
            });

            const newIsUnslick = slidesWidth < (blockSize - 22);
            const newLastRenderedSlideOffset = blockSize * 2;

            if (this.lastRenderedSlideOffset !== newLastRenderedSlideOffset) {
                this.lastRenderedSlideOffset = newLastRenderedSlideOffset;
            }

            if (
                !isAllInitialSlidesMounted ||
                (isAllInitialSlidesMounted && isUnslick !== newIsUnslick)
            ) {
                setTimeout(() => {
                    this.setState({
                        isAllInitialSlidesMounted: true,
                        isUnslick: newIsUnslick,
                    });
                }, 900);
            }

            if (
                isAllInitialSlidesMounted &&
                relationshipData.length > data.length &&
                lastSlideLeftPos <= newLastRenderedSlideOffset
            ) {
                this.dataChunkKey += this.dataChunkKey;

                this.setState({
                    data: [...data, ...this.dataChunks[this.dataChunkKey]],
                });
            }
        }
    }

    render() {
        const {
            blockSize,
            desktopBackgroundHeight,
            desktopHeight,
            handleRef,
            style,
        } = this.props;

        const {
            data,
            isAllInitialSlidesMounted,
            isUnslick,
            shouldRenderSlider,
        } = this.state;

        const bemClass = 'person_record--relationship_connections';
        const containerClasses = bemClass;
        const isBlockMinWidth768 = blockSize >= 768;
        let containerHeight: string | number = 'auto';

        if (blockSize >= 768) {
            containerHeight = desktopHeight;
        }

        return (
            <div
                className={containerClasses}
                ref={handleRef}
                style={{
                    ...style,
                    height: !isAllInitialSlidesMounted ? 0 : containerHeight,
                }}
            >
                <div
                    className={`${bemClass}_inner_container`}
                    style={{
                        opacity: isAllInitialSlidesMounted ? 1 : 0,
                        transform: isAllInitialSlidesMounted ?
                            'translate3d(0, 0, 0)' :
                            'translate3d(0, 44px, 0)',
                    }}
                >
                    {shouldRenderSlider && (
                        <React.Fragment>
                            {!isBlockMinWidth768 && (
                                <Header
                                    as="h2"
                                    className="person_record--relationship_connections_header_title"
                                >
                                    Relationships
                                </Header>
                            )}

                            <Slider
                                afterChange={this.onSliderAfterChange}
                                arrows={false}
                                beforeChange={this.onSliderBeforeChange}
                                className="person_record--relationship_connections_react_slick"
                                infinite={false}
                                ref={(ref) => { this.sliderRef = ref; }}
                                slideSpacing={55}
                                slidesToScroll={1}
                                speed={500}
                                swipeToSlide
                                unslick={isUnslick}
                                variableWidth
                            >
                                {_.map(data, (connection, index) => {
                                    const willBeLastRenderedSlide = data.length === index + 1;

                                    return (
                                        <RelationshipConnectionCard
                                            data={connection}
                                            isLastSlide={willBeLastRenderedSlide}
                                            key={`person-relation-slide-${connection.personId}`}
                                            lastSlideDidMount={this.lastSlideDidMount}
                                            onClick={this.onCardClick}
                                            showRelationshipsTooltip
                                        />
                                    );
                                })}
                            </Slider>

                            <div
                                className="person_record--relationship_connections_background"
                                ref={this.backgroundRef}
                                style={{
                                    height: isBlockMinWidth768 ?
                                        `${desktopBackgroundHeight}px` :
                                        171,
                                }}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

const ConnectedRelationshipConnectionsCarousel = withRouter(
    connect(
        mapStateToProps,
        null,
        null,
        {
            forwardRef: true,
        },
    )(RelationshipConnectionsCarousel) as any,
);

export default React.forwardRef<Ref, PropTypes>((props, ref) => (
    <ConnectedRelationshipConnectionsCarousel
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        forwardedRef={ref}
    />
));
