import './record.scss';

import React, { createRef } from 'react';
import {
    Drawer,
    Fade,
    Page,
} from '@saddlebackchurch/react-cm-ui';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles';
import withWidth from '@saddlebackchurch/react-cm-ui/core/utils/withWidth';
import ClassNames from 'classnames';
import {
    get,
    isNil,
    noop,
    toNumber,
} from 'lodash';
import { connect } from 'react-redux';
import {
    withRouter,
    InjectedRouter,
} from 'react-router';
import appHeaderActions from '../../app/appHeader/appHeader.actions.js';
import { i18n } from '../../global/constants.js';
import FeatureConstants from '../../global/featureConstants';
import { Breakpoint } from '../../global/models';
import UserAccessStore from '../../global/userAccessStore.js';
import { USER_PERMISSIONS } from '../../global/userPermissionConstants.js';
import helpActions from '../../help/help.actions.js';
import appStore from '../../js/stores/AppStore.js';
import { Person } from '../global/models';
import ActionBar from './actionBar';
import DetailsWindow from './detailsWindow';
import commonlyAttendedServiceActions from './global/commonlyAttendedServiceActions.js';
import {
    DEFAULT_PERSON_RECORD_TABS,
    DEFAULT_TABS_VALUES,
} from './global/constants';
import coreMilestonesActions from './global/coreMilestones.actions';
import { updateRecordNavigationItems } from './global/navigationUtils';
import personActions from './global/personActions';
import { PersonNoteModalConnected as PersonNoteModal } from './global/personNoteModal.jsx';
import personNotesActions from './global/personNotesActions.js';
import PersonNotesDrawer, { DRAWER_MAX_WIDTH_CONTRACT_DESKTOP } from './global/personNotesDrawer.jsx';
import {
    reset as resetAction,
    setBlockType as setBlockTypeAction,
    setCanReadPersonNotes as setCanReadPersonNotesAction,
    setIsNotesDrawerOpen as setIsNotesDrawerOpenAction,
    setLifeStageType as setLifeStageTypeAction,
} from './global/personRecord.actions.js';
import relationshipConnectionsActions from './global/relationshipConnections.actions';
import {
    DetailsWindowType,
    Relationship,
    Tab,
} from './models';
import {
    BEM_BLOCK_NAME,
    PERSON_RECORD_TITLE,
} from './recordConstants';
import TabsContentEngagement from './tabsContentEngagement';
import TabsContentMinistryAndServing from './tabsContentMinistryAndServing.jsx';
import TabsContentPersonal from './tabsContentPersonal';
import TabsContentSmallGroups from './tabsContentSmallGroups.jsx';
// NOTE: Test Drawer Page located at: /people/search-or-add/record/204093/drawer-test

interface PropTypesTs {
    actionBarChildren: React.ReactNode;
    actionBarControls: {
        md: any[];
        sm: any[];
        smControls: any[];
    };
    blockSize: number;
    canReadPersonNotes: boolean;
    classes: {
        root: string;
    };
    detailsWindow: DetailsWindowType;
    elements: {
        dw: HTMLElement | null;
        dwBottomShadow: HTMLElement | null;
        dwCoreMilestonesCol: HTMLElement | null;
        dwInner: HTMLElement | null;
        dwPersonAvatar: HTMLElement | null;
        dwPersonAvatarCol: HTMLElement | null;
        dwPersonInfo: HTMLElement | null;
        dwPersonInfoRowLast: HTMLElement | null;
        dwSubNav: HTMLElement | null;
        spPersonal: HTMLElement | null;
        spPersonalRc: HTMLElement | null;
        spPersonalInner: HTMLElement | null;
    };
    fullName: string;
    id: number;
    hasBackButton: boolean | null;
    isAdult: boolean | null;
    isChild: boolean | null;
    isCoreMilestonesDataFetching: boolean;
    isDrawerChild: boolean | null;
    isNotesDrawerExpanded: boolean | null;
    isNotesDrawerOpen: boolean | null;
    isPersonalDataFetching: boolean;
    isRelationshipsDataFetching: boolean;
    isStudent: boolean | null;
    isPersonRecordV2Enabled: boolean;
    location: {
        query: {
            callback: string;
        };
    };
    noteDefaultCategoryId: number | null;
    notesEntityType: string | null;
    notesEntityId: number | null;
    noteFormStateChange: boolean;
    noteId: number | null;
    onRelationshipCardClick: () => void;
    shouldOverrideActions: boolean | null;
    params: {
        personId: string;
        noteId: string;
    };
    person: Person;
    personId: number | null;
    relationshipConnectionApiParams: {
        checkInValidation?: boolean;
    } | null;
    relationshipData: Relationship[];
    reset: () => void;
    router: InjectedRouter;
    selectedTabIndex: number | null;
    setBlockType: (blocType: string) => void;
    setCanReadPersonNotes: (canReadPersonNotes: boolean) => void;
    setIsNotesDrawerOpen: (canReadPersonNotes: boolean) => void;
    setLifeStageType: (isAdult: boolean, isChild: boolean, isStudent: boolean) => void;
    shouldInitialNotesDrawerOpen: boolean | null;
    tabs: Tab[];
    userAccess: UserAccessStore;
    width: string | null;
    wingType: string;
    winWidth: number;
    onCloseRecord: () => void;
}

interface StateTypes {
    blockSize: number;
    personId: number;
    tabs: Tab[];
    selectedTabIndex: number;
    noteId: number;
}

const defaultSelectedTabIndex = 0;

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            featureToggles,
            securityContext: { userAccess },
        },
        breakpoint: {
            winWidth,
        },
        people: {
            record: {
                coreMilestones: {
                    isFetching: isCoreMilestonesDataFetching,
                },
                index: {
                    canReadPersonNotes,
                    detailsWindow,
                    elements,
                    isNotesDrawerExpanded,
                    isNotesDrawerOpen,
                },
                noteForm: {
                    selectedNote,
                },
                notes: {
                    noteFormStateChange,
                    wingType,
                },
                person: {
                    data: dataPerson,
                    isFetching: isPersonalDataFetching,
                },
                relationships: {
                    data: relationshipData,
                    isFetching: isRelationshipsDataFetching,
                },
            },
        },
    } = state;

    const {
        firstName,
        isAdult,
        isChild,
        isStudent,
        lastName,
    } = dataPerson;

    const isPersonRecordV2Enabled = featureToggles.isFeatureEnabled(
        FeatureConstants.PersonRecord_2_0,
    );

    return {
        canReadPersonNotes,
        dataPerson,
        detailsWindow,
        elements,
        fullName: `${firstName} ${lastName}`,
        isAdult,
        isChild,
        isCoreMilestonesDataFetching,
        isNotesDrawerExpanded,
        isNotesDrawerOpen,
        isPersonalDataFetching,
        isPersonRecordV2Enabled,
        isRelationshipsDataFetching,
        isStudent,
        noteFormStateChange,
        relationshipData,
        selectedNote,
        userAccess,
        winWidth,
        wingType,
    };
};

const mapDispatchToProps = {
    reset: resetAction,
    setBlockType: setBlockTypeAction,
    setCanReadPersonNotes: setCanReadPersonNotesAction,
    setIsNotesDrawerOpen: setIsNotesDrawerOpenAction,
    setLifeStageType: setLifeStageTypeAction,
};

const styles = () => ({
    root: {
        '&.person_record': {
            '&--mobile': {
                '& .drawer--data_groups': {
                    marginLeft: '-22px !important',
                    marginRight: '-22px !important',
                },
            },
            '&--desktop': {
                '& .person_record--tabs_content:not(.person_record--tabs_content-personal)': {
                    marginTop: 30,
                },
                '& .drawer--data_groups': {
                    marginLeft: '-44px !important',
                    marginRight: '-44px !important',
                },
            },
        },
        '& .person_record--tabs_content:not(.person_record--tabs_content-personal)': {
            paddingTop: ({ width }) => (width === 'sm' ? 281 : 0),
            width: ({ isNotesDrawerOpen }) => (isNotesDrawerOpen ?
                `calc(100% - ${DRAWER_MAX_WIDTH_CONTRACT_DESKTOP}px)` :
                'auto'
            ),
        },
        '& .drawer--data_groups': {
            flexWrap: ({ isNotesDrawerOpen }) => (isNotesDrawerOpen ? 'wrap' : 'unset'),
            '& .page--data_groups_column, & .drawer--data_groups_column': {
                flex: '1 1 0',
            },
        },
    },
});

const LG_DW_MAX_HEIGHT = 302;
const LG_DW_MIN_HEIGHT = 156;
const LG_RC_HEIGHT = 221;
const LG_RC_Y = -74;
const MAX_HEIGHT_INFO_BAR_NOT_PERSONAL = 224;
const NOTES_DESKTOP_MIN_BLOCK_SIZE = 865;
const SM_DW_DEFAULT_HEIGHT = 270;
const TRANSITION_APPEAR_TIMEOUT = 600;

function isPassiveSupported() {
    let supportsPassive = false;

    try {
        const opts = Object.defineProperty({}, 'passive', {
            get: () => {
                supportsPassive = true;

                return true;
            },
        });

        window?.addEventListener('testPassive', null, opts);
        window?.removeEventListener('testPassive', null, opts);
    } catch (e) {
        supportsPassive = false;
    }

    return supportsPassive;
}

export class Record extends React.Component<PropTypesTs, StateTypes> {
    containerRef: React.RefObject<HTMLDivElement>;

    drawerScrollbarContentRef: React.MutableRefObject<HTMLDivElement>;

    defaultLgDwnCoreMilestonesHeight: number;

    defaultLgDwnCoreMilestonesPaddingBottom: number;

    drawerScrollContainer: HTMLElement | null;

    latestKnownScrollY: number;

    resetDwStyles: boolean;

    shouldInitialNotesDrawerOpen: boolean;

    ticking: boolean;

    updateMdDwnEls: boolean;

    updateMdUpEls: boolean;

    spPersonalRcElScrollPosY: number;

    isBlWdMdDwn: boolean;

    isBlWdMdUp: boolean;

    detailsWindowHeight: string;

    rccScrollPosY: number;

    constructor(props) {
        super(props);

        const {
            isDrawerChild = false,
            selectedTabIndex = defaultSelectedTabIndex,
            tabs = DEFAULT_PERSON_RECORD_TABS,
        } = props;

        helpActions.getFilteredHelpArticles({
            module: 'People',
            name: 'Search',
        });

        if (props.isPersonRecordV2Enabled) {
            appHeaderActions.updateTitle(i18n('Person Record'));
        }

        this.containerRef = React.createRef();
        this.drawerScrollbarContentRef = createRef<HTMLDivElement>();

        this.defaultLgDwnCoreMilestonesHeight = 0;
        this.defaultLgDwnCoreMilestonesPaddingBottom = 0;
        this.drawerScrollContainer = null;
        this.latestKnownScrollY = 0;
        this.resetDwStyles = false;
        this.shouldInitialNotesDrawerOpen = false;
        this.ticking = false;
        this.updateMdDwnEls = true;
        this.updateMdUpEls = true;

        this.onScroll = this.onScroll.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.setBlWdVars = this.setBlWdVars.bind(this);
        this.spPersonalRcElScrollPosY = LG_RC_Y;
        this.updateScrollShrinkEls = this.updateScrollShrinkEls.bind(this);

        this.isBlWdMdDwn = props.blockSize?.offsetWidth < Breakpoint.md;
        this.isBlWdMdUp = props.blockSize?.offsetWidth >= Breakpoint.md;

        appStore.toggleBodyClass(false);
        props.setBlockType(props.isDrawerChild ? 'drawer' : 'page');

        this.state = {
            blockSize: isDrawerChild ? null : props.blockSize,
            noteId: this.getNoteId(),
            personId: this.getPersonId(),
            selectedTabIndex,
            tabs,
        };
    }

    componentDidMount() {
        const {
            isDrawerChild,
            router,
            setCanReadPersonNotes,
            userAccess,
        } = this.props;

        const {
            personId,
        } = this.state;

        if (!isDrawerChild) {
            updateRecordNavigationItems(personId, router);
        }

        this.getDataActions();

        let scrollContainer: any = window;

        if (isDrawerChild) {
            this.drawerScrollContainer = this.containerRef?.current?.closest?.('.drawer-container-inner')?.parentElement;
            scrollContainer = this.drawerScrollContainer;
        } else {
            this.setState({
                blockSize: this.containerRef.current?.offsetWidth ?? 0,
            });
        }

        this.drawerScrollbarContentRef.current = document.querySelector('.person_record_drawer .drawer-container > div > div');

        scrollContainer?.addEventListener(
            'scroll',
            this.onScroll,
            isPassiveSupported() ? { passive: true } : false,
        );

        setCanReadPersonNotes(
            userAccess.hasPermission(USER_PERMISSIONS.readPersonNotes),
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            blockSize: prevBlockSizeProp,
            detailsWindow: prevDetailsWindow,
            elements: prevElements,
            isNotesDrawerExpanded: prevIsNotesDrawerExpanded,
            isNotesDrawerOpen: prevIsNotesDrawerOpen,
            isPersonalDataFetching: wasPersonalDataFetching,
            tabs: prevTabs,
            selectedTabIndex: prevSelectedTabIndex,
            winWidth: prevWinWidth,
        } = prevProps;

        const {
            canReadPersonNotes: nextCanReadPersonNotes,
            detailsWindow: nextDetailsWindow,
            elements: nextElements,
            blockSize: blockSizeProp,
            fullName: nextFullName,
            isAdult: nextIsAdult,
            isChild: nextIsChild,
            isCoreMilestonesDataFetching: nextIsCoreMilestonesDataFetching,
            isDrawerChild: nextIsDrawerChild,
            isNotesDrawerExpanded: nextIsNotesDrawerExpanded,
            isNotesDrawerOpen: nextIsNotesDrawerOpen,
            isPersonalDataFetching: nextIsPersonalDataFetching,
            isRelationshipsDataFetching: nextIsRelationshipsDataFetching,
            isStudent: nextIsStudent,
            noteFormStateChange: nextNoteFormStateChange,
            params,
            personId: nextPersonId,
            selectedTabIndex,
            setIsNotesDrawerOpen,
            setLifeStageType,
            tabs: nextTabs,
            winWidth: nextWinWidth,
        } = this.props;

        const {
            personId: nextParamsPersonId,
        } = params || {};

        const {
            blockSize: prevBlockSizeState,
        } = prevState;

        const {
            blockSize: nextBlockSizeState,
            personId,
        } = this.state;

        const prevIsBlWdMdDwn = this.isBlWdMdDwn;
        const prevIsBlWdMdUp = this.isBlWdMdUp;

        const prevActiveTab = this.getActiveTab(prevTabs);
        const currentActiveTab = this.getActiveTab(nextTabs);

        const shouldResetTabScrollPosition = prevActiveTab?.label !== currentActiveTab?.label &&
            this.drawerScrollbarContentRef.current;

        if (shouldResetTabScrollPosition) {
            this.drawerScrollbarContentRef.current.scroll(0, 0);
        }

        if (
            (nextIsDrawerChild && nextPersonId !== personId) ||
            (!nextIsDrawerChild && toNumber(nextParamsPersonId) !== personId)
        ) {
            this.setState({
                personId: this.getPersonId(),
            }, () => {
                const { personId: nextStatePersonId } = this.state;

                if (!isNil(nextStatePersonId)) {
                    this.getDataActions();
                }
            });
        }

        if (prevBlockSizeState !== nextBlockSizeState) {
            this.setBlWdVars();
        }

        /**
         * TODO: Changing the document.title here is just for to keep track of an idea that I
         * (Cameron) have, because our back/previous history sucks. Also, it was just plain fun
         * to see what could be done in a small amount of time.
         * We probably could take this into Redux and update in app.jsx or create a utility function
         * that updates the title.
         * Oh, don't forget the componentWillUnmount with whatever we end up doing with this idea!
         */
        if (
            wasPersonalDataFetching && !nextIsPersonalDataFetching && nextFullName
        ) {
            document.title = `Healthy Church People - Person Record (${nextFullName})`;
            appHeaderActions.updateTitle(`${PERSON_RECORD_TITLE} ${this.getPersonId()}`);
            setLifeStageType(nextIsAdult, nextIsChild, nextIsStudent);
        }

        if (nextIsDrawerChild && ((prevBlockSizeProp !== blockSizeProp) ||
            (isNil(nextBlockSizeState) && !isNil(blockSizeProp)))
        ) {
            this.setState({
                blockSize: blockSizeProp,
            });
        }

        if (prevWinWidth !== nextWinWidth) {
            const zoomRatio = Math.round(window.devicePixelRatio * 100);

            if (nextWinWidth > Breakpoint.md && zoomRatio < 100) {
                this.setState({
                    blockSize: Breakpoint.md,
                });
            } else {
                this.setState({
                    blockSize: this.containerRef.current?.offsetWidth ?? 0,
                });
            }
        }

        if (prevDetailsWindow !== nextDetailsWindow) {
            this.setDetailsWindowHeight();
        }

        if (prevTabs !== nextTabs) {
            this.setState({
                tabs: nextTabs ?? DEFAULT_PERSON_RECORD_TABS,
            });
        }

        if (prevSelectedTabIndex !== selectedTabIndex) {
            this.setState({
                selectedTabIndex,
            });
        }

        if (
            !wasPersonalDataFetching &&
            !nextIsPersonalDataFetching &&
            (
                prevTabs !== nextTabs ||
                prevIsBlWdMdDwn !== this.isBlWdMdDwn ||
                prevIsBlWdMdUp !== this.isBlWdMdUp ||
                prevElements !== nextElements
            )
        ) {
            this.setDetailsWindowHeight();
            this.setInlineStyles();
        }

        if (
            prevElements !== nextElements
        ) {
            this.updateResizeEls();
        }

        if (
            this.shouldInitialNotesDrawerOpen &&
            nextCanReadPersonNotes &&
            nextWinWidth >= NOTES_DESKTOP_MIN_BLOCK_SIZE &&
            (
                nextIsCoreMilestonesDataFetching ||
                nextIsPersonalDataFetching ||
                nextIsRelationshipsDataFetching
            )
        ) {
            this.shouldInitialNotesDrawerOpen = false;

            setTimeout(() => {
                setIsNotesDrawerOpen(true);
            }, TRANSITION_APPEAR_TIMEOUT + 50); // 50 is an offset, not based on any other node.
        }

        if (
            nextIsNotesDrawerOpen &&
            prevWinWidth !== nextWinWidth &&
            nextWinWidth < NOTES_DESKTOP_MIN_BLOCK_SIZE
        ) {
            setIsNotesDrawerOpen(false);
        }

        if (nextNoteFormStateChange && prevIsNotesDrawerOpen !== nextIsNotesDrawerOpen) {
            setTimeout(() => {
                personNotesActions.setWingType('new_note_form');
            }, 300);
        }

        if (nextWinWidth >= NOTES_DESKTOP_MIN_BLOCK_SIZE) {
            if (
                (
                    nextIsNotesDrawerExpanded &&
                    nextIsNotesDrawerOpen &&
                    prevIsNotesDrawerExpanded !== nextIsNotesDrawerExpanded
                ) ||
                (
                    !prevIsNotesDrawerOpen &&
                    nextIsNotesDrawerOpen &&
                    nextIsNotesDrawerExpanded
                )
            ) {
                document.body.classList.add('drawer-notes-expanded');
            }

            if (
                (
                    !nextIsNotesDrawerExpanded &&
                    nextIsNotesDrawerOpen &&
                    prevIsNotesDrawerExpanded !== nextIsNotesDrawerExpanded
                ) ||
                (
                    prevIsNotesDrawerOpen &&
                    !nextIsNotesDrawerOpen
                )
            ) {
                document.body.classList.remove('drawer-notes-expanded');
            }
        }
    }

    componentWillUnmount() {
        const {
            isDrawerChild,
            reset,
        } = this.props;
        const scrollContainer = isDrawerChild ? this.drawerScrollContainer : window;

        document.title = 'Healthy Church';

        scrollContainer?.removeEventListener('scroll', this.onScroll);
        appStore.toggleBodyClass(true);
        personActions.resetPersonal();
        relationshipConnectionsActions.resetRelationships();
        coreMilestonesActions.resetCoreMilestones();
        personNotesActions.resetNoteFilters();
        document.body.classList.remove('drawer-notes-expanded');

        reset();
    }

    onScroll() {
        const {
            elements,
            isDrawerChild,
        } = this.props;

        const {
            dw: dwEl,
            spPersonal: spPersonalEl,
            spPersonalInner: spPersonalInnerEl,
            spPersonalRc: spPersonalRcEl,
        } = elements;

        const {
            scrollY,
        } = window;

        const activeTab = this.getActiveTab();
        const isPersonalTabActive = activeTab?.value === DEFAULT_TABS_VALUES.personal;

        const isSpPersonal = activeTab?.value === DEFAULT_TABS_VALUES.personal &&
            !!spPersonalEl &&
            !!spPersonalInnerEl &&
            !!spPersonalRcEl;

        if (isDrawerChild) {
            this.latestKnownScrollY = this.drawerScrollContainer.scrollTop;
        } else {
            this.latestKnownScrollY = scrollY;
        }

        if (dwEl) {
            if (isPersonalTabActive) {
                const spPersonalInnerRect = spPersonalInnerEl.getBoundingClientRect();
                const spPersonalInnerTop = spPersonalInnerRect.top;
                const spPersonalInnerHeight = spPersonalInnerRect.height;

                if (
                    !this.ticking &&
                    (
                        !isSpPersonal ||
                        (
                            isSpPersonal &&
                            spPersonalInnerTop + spPersonalInnerHeight >= document.body.clientHeight
                        )
                    )
                ) {
                    window.requestAnimationFrame(() => {
                        if (this.isBlWdMdUp) {
                            this.updateScrollLgEls(this.latestKnownScrollY);
                        } else {
                            this.updateScrollShrinkEls(this.latestKnownScrollY);
                        }

                        this.ticking = false;
                    });

                    this.ticking = true;
                }
            } else {
                // future custom tabs will need to have this class inside the root container to work.
                const tabContentContainer = document.querySelector('.person_record--tabs_content');

                if (!tabContentContainer) {
                    return;
                }

                const {
                    top,
                    height,
                } = tabContentContainer?.getBoundingClientRect();

                const shouldUpdateElementsOnScroll = !this.ticking &&
                    top + height >= document.body.clientHeight;

                if (shouldUpdateElementsOnScroll) {
                    window.requestAnimationFrame(() => {
                        if (this.isBlWdMdUp) {
                            this.updateScrollLgEls(this.latestKnownScrollY);
                        } else {
                            this.updateScrollShrinkEls(this.latestKnownScrollY);
                        }

                        this.ticking = false;
                    });

                    this.ticking = true;
                }
            }
        }
    }

    onTabClick({ selectedTabIndex }) {
        this.setState({
            selectedTabIndex,
        });
    }

    getActiveTab(tabs?: Tab[]): Tab | null {
        const {
            selectedTabIndex,
            tabs: tabsState,
        } = this.state;

        const theTabs = tabs ?? tabsState;

        return theTabs[selectedTabIndex];
    }

    getDataActions() {
        const {
            isDrawerChild,
            isPersonRecordV2Enabled,
            relationshipConnectionApiParams = {},
            shouldInitialNotesDrawerOpen,
            userAccess,
        } = this.props;

        const {
            personId,
            noteId,
        } = this.state;

        if (isNil(personId)) {
            return null;
        }

        if (!isPersonRecordV2Enabled &&
            !isDrawerChild) {
            this.redirectToPersonRecordV1();
        } else {
            const hasReadPersonMilestonesPermissions = userAccess.hasPermission(
                USER_PERMISSIONS.readPersonMilestones,
            );

            const hasReadPersonRelationshipsPermissions = userAccess.hasPermission(
                USER_PERMISSIONS.readPersonRelationships,
            );

            this.shouldInitialNotesDrawerOpen = shouldInitialNotesDrawerOpen;

            (commonlyAttendedServiceActions as any).get({ personId });

            if (hasReadPersonMilestonesPermissions) {
                coreMilestonesActions.getCoreMilestones({ personId });
            }

            personActions.getPersonal({
                personId,
                suppressMergeRedirect: true,
            }).catch(noop);

            if (hasReadPersonRelationshipsPermissions) {
                (relationshipConnectionsActions as any).getRelationships({
                    personId,
                    ...relationshipConnectionApiParams,
                });
            }

            if (noteId) {
                (personNotesActions as any).getNote({ noteId });
            }
        }

        return null;
    }

    getNoteId() {
        const {
            isDrawerChild,
            params,
            noteId,
        } = this.props;
        const {
            noteId: paramsNoteId,
        } = params || {};

        return isDrawerChild ? noteId : toNumber(paramsNoteId);
    }

    getPersonId() {
        const {
            isDrawerChild,
            params,
            personId,
        } = this.props;

        const {
            personId: paramsPersonId,
        } = params || {};

        return isDrawerChild ? personId : toNumber(paramsPersonId);
    }

    setBlWdVars() {
        const { blockSize } = this.state;

        this.isBlWdMdDwn = blockSize < Breakpoint.md;
        this.isBlWdMdUp = blockSize >= Breakpoint.md;
    }

    setDetailsWindowHeight() {
        const {
            detailsWindow,
        } = this.props;
        const activeTab = this.getActiveTab();
        let detailsWindowHeight = null;

        if (
            this.isBlWdMdUp &&
            detailsWindow &&
            detailsWindow.md &&
            detailsWindow.md.height
        ) {
            detailsWindowHeight = detailsWindow.md.height;
        } else if (
            !this.isBlWdMdUp &&
            detailsWindow &&
            detailsWindow.sm &&
            detailsWindow.sm.height
        ) {
            detailsWindowHeight = detailsWindow.sm.height;
        } else if (this.isBlWdMdUp) {
            detailsWindowHeight = activeTab?.value === DEFAULT_TABS_VALUES.personal ? `${LG_DW_MAX_HEIGHT}px` : '224px';
        }

        this.detailsWindowHeight = detailsWindowHeight;
    }

    setInlineStyles() {
        const {
            detailsWindow,
            elements: {
                dw: dwEl,
                dwBottomShadow,
                dwSubNav: dwSubNavEl,
                dwInner: dwInnerEl,
            },
        } = this.props;
        const activeTab = this.getActiveTab();

        if (dwEl && dwInnerEl) {
            const transitionHeight = 'height 166ms ease-out';

            window.scroll(0, 0);

            dwEl.style.zIndex = null;
            dwEl.style.transition = transitionHeight;
            dwInnerEl.style.transition = transitionHeight;
            dwSubNavEl.style.marginTop = '';
            dwEl.style.height = this.detailsWindowHeight;
            dwInnerEl.style.height = this.detailsWindowHeight;
            dwBottomShadow.style.display = 'none';

            if (
                activeTab?.value === DEFAULT_TABS_VALUES.personal ||
                (
                    this.isBlWdMdUp &&
                    detailsWindow &&
                    detailsWindow.md &&
                    detailsWindow.md.removeTransitionStyle
                ) ||
                (
                    this.isBlWdMdDwn &&
                    detailsWindow &&
                    detailsWindow.sm &&
                    detailsWindow.sm.removeTransitionStyle
                )
            ) {
                setTimeout(() => {
                    dwEl.style.transition = null;
                    dwInnerEl.style.transition = null;
                }, 300);
            }
        }
    }

    redirectToPersonRecordV1() {
        const {
            isPersonRecordV2Enabled,
            location: {
                query: {
                    callback,
                },
            },
            params,
            router,
        } = this.props;
        let { pathname } = window.location;
        const noteId = get(params, 'noteId');
        if (!isPersonRecordV2Enabled) {
            const shouldHandleReminders = !isNil(noteId);
            const noteWithRemindersRoute = shouldHandleReminders && pathname;

            if (shouldHandleReminders) {
                pathname = pathname.replace(`/note/${noteId}`, '');
            }

            let url = `${pathname}/edit`;
            const queryParams = [];

            if (callback === 'history') {
                queryParams.push('callback=history');
            }

            if (shouldHandleReminders) {
                queryParams.push(`shouldAskEnablePersonV2=true&redirectOnEnablePersonV2=${noteWithRemindersRoute}`);
            }

            if (queryParams.length > 0) {
                url += `?${queryParams.join('&')}`;
            }

            router.push(url);
        }
    }

    updateResizeEls() {
        const {
            elements: {
                dw: dwEl,
                dwCoreMilestonesCol: dwCoreMilestonesColEl,
                dwInner: dwInnerEl,
                dwPersonAvatar: dwPersonAvatarEl,
                dwPersonAvatarCol: dwPersonAvatarColEl,
                spPersonal: spPersonalEl,
                spPersonalRc: spPersonalRcEl,
            },
            isPersonalDataFetching,
            relationshipData,
            winWidth,
        } = this.props;

        if (
            dwEl &&
            dwInnerEl &&
            dwPersonAvatarEl &&
            dwPersonAvatarColEl
        ) {
            if (
                this.isBlWdMdDwn &&
                winWidth < Breakpoint.md &&
                this.defaultLgDwnCoreMilestonesHeight !== 73 &&
                this.defaultLgDwnCoreMilestonesPaddingBottom !== 22
            ) {
                this.defaultLgDwnCoreMilestonesHeight = 73;
                this.defaultLgDwnCoreMilestonesPaddingBottom = 22;
            }

            if (
                this.isBlWdMdDwn &&
                winWidth >= Breakpoint.md &&
                winWidth < Breakpoint.lg &&
                this.defaultLgDwnCoreMilestonesHeight !== 84 &&
                this.defaultLgDwnCoreMilestonesPaddingBottom !== 33
            ) {
                this.defaultLgDwnCoreMilestonesHeight = 84;
                this.defaultLgDwnCoreMilestonesPaddingBottom = 33;
            }

            if (this.isBlWdMdDwn && this.updateMdDwnEls) {
                this.updateMdDwnEls = false;
                this.updateMdUpEls = true;

                dwEl.style.zIndex = null;
                dwEl.style.height = null;

                if (dwInnerEl) {
                    dwInnerEl.style.height = null;
                }

                if (spPersonalRcEl) {
                    spPersonalRcEl.style.transform = 'translate3d(0, 0, 0)';
                }

                window.scrollTo(0, 0);

                /**
                 * Commenting this out for now, since when I was showing it to the team's
                 * Person Record 2.0 03/19/2020 checkpoint it seemed to be working pretty well.
                 * I don't want to delete just yet until more testing has been done.
                 */
                // setTimeout(() => {
                //     this.updateScrollShrinkEls(0);
                // }, 150);
            }

            if (this.isBlWdMdUp && this.updateMdUpEls) {
                const activeTab = this.getActiveTab();
                const isPersonalTabActive = activeTab?.value === DEFAULT_TABS_VALUES.personal;
                const hasRelationships = relationshipData.length > 0;
                const height = isPersonalTabActive && hasRelationships ?
                    LG_DW_MAX_HEIGHT :
                    MAX_HEIGHT_INFO_BAR_NOT_PERSONAL;

                this.updateMdDwnEls = !isPersonalDataFetching;
                this.updateMdUpEls = isPersonalDataFetching;
                this.resetDwStyles = false;

                if (dwCoreMilestonesColEl) {
                    dwCoreMilestonesColEl.style.height = null;
                    dwCoreMilestonesColEl.style.opacity = null;
                    dwCoreMilestonesColEl.style.paddingBottom = '0';
                }

                dwEl.style.height = `${height}px`;
                dwInnerEl.style.height = `${height}px`;

                dwPersonAvatarColEl.style.opacity = null;

                dwPersonAvatarEl.style.height = '100px';
                dwPersonAvatarEl.style.width = '100px';

                if (spPersonalEl) {
                    spPersonalEl.style.top = null;
                }

                if (spPersonalRcEl) {
                    spPersonalRcEl.style.transform = `translate3d(0, ${LG_RC_Y}px, 0)`;
                }

                window.scrollTo(0, 0);
                this.updateScrollLgEls();
            }

            /**
             * Commenting this out for now too, since when I was showing it to the team's
             * Person Record 2.0 03/19/2020 checkpoint it seemed to be working pretty well.
             * I don't want to delete just yet until more testing has been done.
             */
            // if (this.isBlWdMdUp) {
            //     spPersonalRcEl.style.transform = `translate3d(0, ${LG_RC_Y}px, 0)`;
            // } else {
            //     spPersonalRcEl.style.transform = 'translate3d(0, 0, 0)';
            // }
        }
    }

    updateScrollLgEls(currentScrollY?: number) {
        const {
            elements: {
                dw: dwEl,
                dwInner: dwInnerEl,
                dwSubNav,
                spPersonalRc: spPersonalRcEl,
            },
            relationshipData,
        } = this.props;

        const activeTab = this.getActiveTab();

        if (activeTab?.disableScrollLgEls) {
            return;
        }

        const isPersonalTabActive = activeTab?.value === DEFAULT_TABS_VALUES.personal;
        const hasRelationships = relationshipData.length > 0;
        const maxHeight = isPersonalTabActive && hasRelationships ?
            LG_DW_MAX_HEIGHT :
            MAX_HEIGHT_INFO_BAR_NOT_PERSONAL;

        const minimunScrollTravel = isPersonalTabActive && hasRelationships ? LG_DW_MIN_HEIGHT : 30;
        if (currentScrollY >= minimunScrollTravel) {
            const auxOffSet = isPersonalTabActive && hasRelationships ?
                LG_RC_HEIGHT :
                LG_RC_HEIGHT - 185;

            this.updateScrollShrinkEls(currentScrollY - auxOffSet);
        }

        const minimunScrollY = isPersonalTabActive && hasRelationships ? 294 : 40;

        if (isPersonalTabActive && hasRelationships) {
            dwSubNav.style.bottom = 'unset';
        }

        const shouldHandleNavigationBottomStyle = !isPersonalTabActive || (
            isPersonalTabActive && !hasRelationships
        );

        if (currentScrollY >= minimunScrollY) {
            if (shouldHandleNavigationBottomStyle) {
                // when scrolling down, tabs must adapt a quicker than the details window area (166ms)
                dwSubNav.style.transition = 'bottom 100ms ease-out';
                dwSubNav.style.bottom = '20px';
            }

            this.resetDwStyles = true;
            dwEl.style.zIndex = '6';
            dwInnerEl.style.height = `${LG_DW_MIN_HEIGHT}px`;
            dwInnerEl.style.boxShadow = '0 2px 6px 0 rgba(0,0,0,0.29)';
        } else {
            this.resetDwStyles = false;
            dwEl.style.zIndex = '1';
            dwInnerEl.style.height = `${maxHeight}px`;
            dwInnerEl.style.boxShadow = 'unset';

            if (shouldHandleNavigationBottomStyle) {
                // when scrolling up, tabs must adapt a slower than the details window area (166ms)
                dwSubNav.style.transition = 'bottom 200ms';
                dwSubNav.style.bottom = '-20px';
            }
        }

        if (currentScrollY <= 0) {
            this.updateScrollShrinkEls(0);
        }

        // Sub Page: Personal
        if (spPersonalRcEl && hasRelationships) {
            const spPersonalRcMaxScrollYTravel = Math.min(
                LG_RC_HEIGHT,
                currentScrollY,
            ); // max is 221.
            const spPersonalRcStartPosY = LG_RC_HEIGHT + LG_RC_Y; // 147

            if (currentScrollY >= spPersonalRcStartPosY) {
                this.spPersonalRcElScrollPosY =
                    LG_RC_Y + spPersonalRcMaxScrollYTravel - spPersonalRcStartPosY;

                spPersonalRcEl.style.transform = `translate3d(0, ${this.spPersonalRcElScrollPosY}px, 0)`;
            }

            if (currentScrollY < spPersonalRcStartPosY && this.rccScrollPosY !== LG_RC_Y) {
                this.spPersonalRcElScrollPosY = LG_RC_Y;

                spPersonalRcEl.style.transform = `translate3d(0, ${this.spPersonalRcElScrollPosY}px, 0)`;
            }
        }
    }

    updateScrollShrinkEls(currentScrollY) {
        const {
            elements: {
                dwCoreMilestonesCol: dwCoreMilestonesColEl,
                dwInner: dwInnerEl,
                dwPersonAvatar: dwPersonAvatarEl,
                dwPersonInfo: dwPersonInfoEl,
                dwPersonInfoRowLast: dwPersonInfoRowLastEl,
                dwSubNav: dwSubNavEl,
                spPersonal: spPersonalEl,
            },
            relationshipData,
        } = this.props;

        const activeTab = this.getActiveTab();
        const isPersonalTabActive = activeTab?.value === DEFAULT_TABS_VALUES.personal;
        const hasRelationships = relationshipData.length > 0;

        const defaultLgDwnPersonAvatarAndInfoSize = 100;
        const defaultLgDwnSubNavigationPaddingTop = 11;
        const maxScrollYTravel = isPersonalTabActive && hasRelationships ? 62 : 15;

        const newCurrentScrollY = currentScrollY <= maxScrollYTravel ?
            currentScrollY :
            maxScrollYTravel;

        const scrollPercentage = (speed) => Math.max(
            0,
            (maxScrollYTravel - (newCurrentScrollY * speed)) / maxScrollYTravel,
        );
        const scrollSize = (max, speed, size) => {
            const newSize = Math.max(max, scrollPercentage(speed)) * size;

            if (newSize >= size) {
                return `${size}px`;
            }

            return `${newSize}px`;
        };

        if (this.isBlWdMdDwn) {
            dwCoreMilestonesColEl.style.height = `${Math.min(
                this.defaultLgDwnCoreMilestonesHeight,
                scrollPercentage(1) * this.defaultLgDwnCoreMilestonesHeight,
            )}px`;

            dwCoreMilestonesColEl.style.opacity = `${scrollPercentage(1.55)}`;

            dwCoreMilestonesColEl.style.paddingBottom = `${Math.min(
                this.defaultLgDwnCoreMilestonesPaddingBottom,
                scrollPercentage(1) * this.defaultLgDwnCoreMilestonesPaddingBottom,
            )}px`;

            dwSubNavEl.style.paddingTop = scrollSize(
                0,
                1.7,
                defaultLgDwnSubNavigationPaddingTop,
            );

            if (spPersonalEl) {
                spPersonalEl.style.top = `${
                    currentScrollY >= 1 && dwInnerEl ?
                        dwInnerEl.offsetHeight + newCurrentScrollY :
                        SM_DW_DEFAULT_HEIGHT
                }px`;
            }

            dwInnerEl.style.boxShadow = currentScrollY > 0 ? '0 2px 6px 0 rgba(0,0,0,0.29)' : 'unset';
        }

        dwPersonAvatarEl.style.height = scrollSize(
            0.55,
            1,
            defaultLgDwnPersonAvatarAndInfoSize,
        );

        dwPersonAvatarEl.style.width = scrollSize(
            0.55,
            1,
            defaultLgDwnPersonAvatarAndInfoSize,
        );

        if (dwPersonInfoEl) {
            dwPersonInfoEl.style.height = scrollSize(
                0.55,
                1,
                defaultLgDwnPersonAvatarAndInfoSize,
            );
        }

        if (dwPersonInfoRowLastEl) {
            dwPersonInfoRowLastEl.style.opacity = `${scrollPercentage(6)}`;
        }

        if (this.isBlWdMdUp && isPersonalTabActive && hasRelationships) {
            const maxMarginTop = 29;

            const newMarginTop = Math.min(
                maxMarginTop,
                newCurrentScrollY > 0 && newCurrentScrollY,
            );
            dwSubNavEl.style.marginTop = `${-newMarginTop}px`;
        }
    }

    render() {
        const {
            actionBarChildren,
            actionBarControls,
            classes,
            hasBackButton,
            isCoreMilestonesDataFetching,
            isDrawerChild,
            isNotesDrawerOpen,
            isPersonalDataFetching,
            isPersonRecordV2Enabled,
            isRelationshipsDataFetching,
            noteDefaultCategoryId,
            notesEntityType,
            notesEntityId,
            onRelationshipCardClick = noop,
            person,
            shouldOverrideActions,
            width,
            wingType,
            onCloseRecord,
        } = this.props;

        const {
            blockSize,
            personId,
            noteId,
            selectedTabIndex,
            tabs,
        } = this.state;

        if (!isDrawerChild && !isPersonRecordV2Enabled && isNil(blockSize) && !personId) {
            return null;
        }

        const isMobile = width === 'sm';

        const containerClasses = ClassNames(
            BEM_BLOCK_NAME,
            classes.root,
            {
                'drawer--has_action_bar': isDrawerChild,
                'page--has_action_bar': !isDrawerChild,
                [`ui ${BEM_BLOCK_NAME}--drawer`]: isDrawerChild,
                [`${BEM_BLOCK_NAME}-size_496_plus`]: blockSize >= 496,
                [`${BEM_BLOCK_NAME}-size_768_plus`]: this.isBlWdMdUp,
                [`${BEM_BLOCK_NAME}--mobile`]: isMobile,
                [`${BEM_BLOCK_NAME}--desktop`]: !isMobile,
            },
        );

        const isDataFetching = isPersonalDataFetching ||
            isCoreMilestonesDataFetching ||
            isRelationshipsDataFetching;
        const activeTab = this.getActiveTab();
        let Main: any = 'div';
        let Container: any = 'div';
        let Content = Drawer.Content; // eslint-disable-line prefer-destructuring
        let containerProps: {
            className: string;
            isDataFetching?: boolean;
        } = {
            className: containerClasses,
        };

        if (!isDrawerChild) {
            Main = Page;
            Container = Page.Container;
            Content = Page.Content;
            containerProps = {
                ...containerProps,
                isDataFetching,
            };
        }

        const isPersonNoteModalOpen = !!noteId;
        const shouldRenderActionBar = !isMobile || (!wingType && !isNotesDrawerOpen);

        return (
            <Main
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...containerProps}
                ref={this.containerRef}
            >
                <Fade in>
                    <div
                        className="person_record--transition_container"
                        data-testid="person_record"
                    >
                        {shouldRenderActionBar && (
                            <ActionBar
                                actionBarControls={actionBarControls}
                                hasBackButton={hasBackButton}
                                isBlWdMdUp={this.isBlWdMdUp}
                                isDrawerChild={isDrawerChild}
                                onClose={onCloseRecord}
                                personId={personId}
                                shouldOverrideActions={shouldOverrideActions}
                            >
                                {actionBarChildren}
                            </ActionBar>

                        )}

                        <Container className="person_record--page_container">
                            <Content className="person_record--page_content">
                                <DetailsWindow
                                    blockSize={blockSize}
                                    isDrawerChild={isDrawerChild}
                                    onTabClick={this.onTabClick}
                                    person={person}
                                    personId={personId}
                                    selectedTabIndex={selectedTabIndex}
                                    tabs={tabs}
                                />

                                {activeTab && activeTab?.contentComponent}

                                {activeTab && activeTab?.value === DEFAULT_TABS_VALUES.personal && (
                                    <TabsContentPersonal
                                        blockSize={blockSize}
                                        isDrawerChild={isDrawerChild}
                                        onRelationshipCardClick={onRelationshipCardClick}
                                        personId={personId}
                                    />
                                )}

                                {activeTab &&
                                activeTab?.value === DEFAULT_TABS_VALUES.ministryAndServing && (
                                    <TabsContentMinistryAndServing />
                                )}

                                {activeTab &&
                                activeTab?.value === DEFAULT_TABS_VALUES.smallGroups && (
                                    <TabsContentSmallGroups />
                                )}

                                {activeTab &&
                                    activeTab?.value === DEFAULT_TABS_VALUES.engagement ? (
                                        <TabsContentEngagement
                                            blockSize={blockSize}
                                            personId={personId}
                                        />
                                    ) : null}
                            </Content>
                        </Container>
                    </div>
                </Fade>

                <PersonNotesDrawer
                    // @ts-ignore */
                    entityId={notesEntityId || personId}
                    entityType={notesEntityType}
                    isDrawerChild={isDrawerChild}
                    isMobile={isMobile}
                    noteDefaultCategoryId={noteDefaultCategoryId}
                    openSelectedNote={isPersonNoteModalOpen}
                    personId={personId}
                />

                {!isMobile && isPersonNoteModalOpen && (
                    <PersonNoteModal
                        isOpen={isPersonNoteModalOpen}
                    />
                )}
            </Main>
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        withWidth()(
            withStyles(styles, { withTheme: true })(Record),
        ),
    ) as any,
);
